import Splide from "@splidejs/splide";

new Splide("#slideAchievement", {
  pagination: false,
  perPage: 1,
  breakpoints: {
    640: {
      perPage: 1,
    },
  },
}).mount();


const barAnnouncement = document.getElementById(
  "contact"
);
if (barAnnouncement) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) initialize();
    });
  });

  observer.observe(barAnnouncement);
}

function initialize() {
  var map = L.map("map").setView([48.1244286, -3.0700203], 7).setZoom(6);

  var osmLayer = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
    // LIGNE 20
    attribution: "© OpenStreetMap contributors",
    maxZoom: 19,
  });

  map.addLayer(osmLayer);

  var greenIcon = L.icon({
    iconUrl: "karcher.991048de.webp",

    iconSize: [128, 128], // size of the icon
    // shadowSize: [96, 96], // size of the shadow
    // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62], // the same for the shadow
    // popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });



  let marker = L.marker([48.1244286, -3.0700203], { icon: greenIcon }).addTo(map);
  L.circle([48.1244286, -3.0700203], {
    radius: 110000,
    color: "green",
  }).addTo(map);
}


function dateOfYear() {
  const spanYear = document.getElementById('copy-year');
  if (!spanYear) return false;
  const date = new Date();
  spanYear.innerText = date.getFullYear();
}

dateOfYear();


// async function fetchPlaceDetails(placeId, apiKey) {
//   const url = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apiKey}`;

//   try {
//     const response = await fetch(url);
//     console.log(response);
//     if (!response.ok) {
//       throw new Error(`Erreur HTTP ${response.status}`);
//     }

//     const data = await response.json();

//     if (data.status === "OK") {
//       return data.result;
//     } else {
//       throw new Error(`Erreur API Google Places: ${data.status}`);
//     }
//   } catch (error) {
//     console.error("Erreur lors de la récupération des avis:", error);
//     return null;
//   }
// }

// function displayReviews(reviews) {
//   const container = document.getElementById("avis");

//   if (!reviews || reviews.length === 0) {
//     container.innerHTML =
//       "<p>Aucun avis disponible pour cet établissement.</p>";
//     return;
//   }

//   const list = document.createElement("ul");
//   reviews.forEach((review) => {
//     const listItem = document.createElement("li");
//     listItem.innerHTML = `
//       <p><strong>${review.author_name}</strong> - Note: ${review.rating}/5</p>
//       <p>${review.text}</p>
//     `;
//     list.appendChild(listItem);
//   });

//   container.appendChild(list);
// }

// (async () => {
//   const apiKey = "AIzaSyDyqU3ujOM9gp2MZRGgGx3LbVRt-YEQZUo";
//   const placeId = "ChIJS0xW6ebJEUgRqONxTrhkfoY";
//   const place = await fetchPlaceDetails(placeId, apiKey);

//   if (place) {
//     displayReviews(place.reviews);
//   }
// })();

function initMap() {
  // Remplacez la valeur de `placeId` par l'ID de place de l'entreprise que vous souhaitez récupérer les avis
  const placeId = "ChIJS0xW6ebJEUgRqONxTrhkfoY";

  // Créez une instance de service Places
  const service = new google.maps.places.PlacesService(
    document.createElement("div")
  );

  // Configurez les options de requête pour obtenir les avis
  const request = {
    placeId: placeId,
    fields: ["reviews"],
  };

  // Effectuez la requête pour obtenir les avis
  service.getDetails(request, (place, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      // Affichez les avis dans l'élément HTML
      displayReviews(place.reviews);
    } else {
      console.error("Erreur lors de la récupération des avis:", status);
    }
  });
}

function displayReviews(reviews) {
  const container = document.getElementById("reviews");

  if (!reviews || reviews.length === 0) {
    container.innerHTML =
      "<p>Aucun avis disponible pour cet établissement.</p>";
    return;
  }

  const list = document.getElementById("cardsReviewContainer");
  reviews.forEach((review) => {
    const listItem = document.createElement("div");
    listItem.classList.add("splide__slide", "max-w-[448px]");
    let oneRating = 'none', twoRating = 'none', threeRating = 'none', fourRating = 'none', fiveRating = 'none';
    if (review.rating === 1) {
      oneRating = 'gold';
    } else if (review.rating === 2) {
      oneRating = 'gold';
      twoRating = 'gold';
    } else if (review.rating === 3) {
      oneRating = 'gold';
      twoRating = 'gold';
      threeRating = 'gold';
    } else if (review.rating === 4) {
      oneRating = 'gold';
      twoRating = 'gold';
      threeRating = 'gold';
      fourRating = 'gold';
    } else if (review.rating === 5) {
      oneRating = 'gold';
      twoRating = 'gold';
      threeRating = 'gold';
      fourRating = 'gold';
      fiveRating = 'gold';
    }
    listItem.innerHTML = `
          <div class="max-w-md mx-auto mt-10 mb-20 bg-white rounded-xl shadow-md overflow-hidden w-[95%] lg:w-full">
      <div class="md:flex">
        <div class="p-8 w-[320px] lg:w-[448px] h-[352px]">
          <div class="flex items-center justify-between">
            <div class="text-4xl text-[gold] mr-2">
             <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[gold]" fill="${oneRating}" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 1l3.09 6.31 6.91.98-5 4.85 1.18 6.88L12 17.25l-6.18 3.88 1.18-6.88-5-4.85 6.91-.98L12 1z" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[gold]" fill="${twoRating}" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 1l3.09 6.31 6.91.98-5 4.85 1.18 6.88L12 17.25l-6.18 3.88 1.18-6.88-5-4.85 6.91-.98L12 1z" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[gold]" fill="${threeRating}" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 1l3.09 6.31 6.91.98-5 4.85 1.18 6.88L12 17.25l-6.18 3.88 1.18-6.88-5-4.85 6.91-.98L12 1z" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[gold]" fill="${fourRating}" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 1l3.09 6.31 6.91.98-5 4.85 1.18 6.88L12 17.25l-6.18 3.88 1.18-6.88-5-4.85 6.91-.98L12 1z" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[gold]" fill="${fiveRating}" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 1l3.09 6.31 6.91.98-5 4.85 1.18 6.88L12 17.25l-6.18 3.88 1.18-6.88-5-4.85 6.91-.98L12 1z" />
</svg>


            </div>
            <svg class="w-[18px] h-[18px]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>
          </div>
          <p id="authorText" class="mt-2 text-gray-500 text-ellipsis">${review.text}</p>
          <p class="text-center text-gray-500 italic ">${review.relative_time_description}</p>
          <div class="mt-4 flex items-center">
            <img class="h-10 w-10 rounded-full object-cover" src="${review.profile_photo_url}" alt="Avatar">
            <a id="authorName" href="${review.author_url}" class="no-underline ml-2 text-sm font-medium text-gray-700">${review.author_name}</a>
          </div>
        </div>
      </div>
    </div>
    `;
    list.appendChild(listItem);
  });

  container.appendChild(list);

  new Splide(".reviewsSlide", {
    pagination: true,
    arrows: false,
    autoplay: true,
    type: "loop",
    gap: "40px",
    perPage: 2,
    breakpoints: {
      640: {
        perPage: 1,
      },
    },
  }).mount();
}

// window.initMap = initMap;


const form = document.getElementById('devis-form');
const formContainer = document.getElementById('form-container');

form.addEventListener('submit', function (e) {
  e.preventDefault();

  const formData = new FormData(form);

  fetch('/mail_app/send_email.php', {
    method: 'POST',
    body: formData
  })
    .then(response => response.text())
    .then(data => {
      if (data.includes("Votre message a été envoyé avec succès.")) {
        formContainer.innerHTML = `
                <div class="text-center text-gray-800">
                    <h2 class="text-2xl font-bold mb-4">Merci pour votre demande !</h2>
                    <p>Votre demande a bien été envoyée. Nous reviendrons vers vous le plus rapidement possible.</p>
                </div>
            `;
      } else {
        throw new Error("Erreur lors de l'envoi");
      }
    })
    .catch(error => {
      console.error('Erreur:', error);
      formContainer.innerHTML = `
            <div class="text-center text-red-600">
                <h2 class="text-2xl font-bold mb-4">Une erreur est survenue</h2>
                <p>Désolé, nous n'avons pas pu envoyer votre demande. Veuillez réessayer plus tard.</p>
            </div>
        `;
    });
});
